import {createContext} from 'react';
import {useQuery} from '@apollo/client';
import {GET_BARRIERS, GET_PARKING_BY_ID} from '../api/graphql/GetParkingList';
import {mapParkingListResponse} from '../api/mapResponse';
import {useCookies} from 'react-cookie';
import {useSearchParams} from 'react-router-dom';
import {GET_ORDER_BY_ID} from '../api/graphql/Order';

const GateContext = createContext();

const GateContextProvider = ({children}) => {
  const [cookies, setCookie] = useCookies(['user']);
  let [searchParams, setSearchParams] = useSearchParams();
  const orderId = cookies.order || searchParams.get('order')
  const {phone, plate} = cookies;

  const {data: orderData} = useQuery(GET_ORDER_BY_ID, {
    variables: {
      id: orderId
    },
    fetchPolicy: 'network-only'
  })

  const parkingId = orderData?.order_by_pk?.parking?.id || cookies.parking || searchParams.get('id')
  const order = orderData?.order_by_pk || {}

  const {data} = useQuery(GET_PARKING_BY_ID, {
    variables: {
      id: parkingId
    }, notifyOnNetworkStatusChange: true,
  })

  const {data: barriersData} = useQuery(GET_BARRIERS, {
    variables: {
      id: parkingId
    }, notifyOnNetworkStatusChange: true,
  })



  const item = mapParkingListResponse(data?.parking_by_pk || {})
  const gates = data?.parking_by_pk?.gates || []
  const barrier = barriersData?.gate.length > 0 ? barriersData?.gate[0] : null

  return (
    <GateContext.Provider value={{
      parkingId,
      orderId,
      item,
      gates,
      order,
      phone,
      plate,
      barrier,
    }}>
      {children}
    </GateContext.Provider>
  );
};

export {
  GateContext,
  GateContextProvider,
}
